.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
  overflow: hidden;
  position: relative;
}

.spinner-logo {
  width: 50px;
  height: 50px;
  animation: fadeUp 2s linear infinite;
  mask-image: linear-gradient(to top, transparent, black);
  -webkit-mask-image: linear-gradient(to top, transparent, black);
}

@keyframes fadeUp {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(-10px);
    opacity: 0.5;
  }
  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
}
